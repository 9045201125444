import * as React from 'react'
import { getBlockClass } from '../../../../../../utilities/helpers'
import { PROMOTION_SIDEBAR_FORM_ROOT_CLASS } from '../helpers'
import { PromotionCampaignValidationRewardGroup } from '../validation'
import { PromotionRulesViewModeUI } from './rule-view-mode-ui'
import '../styles.scss'

type PromotionRewardGroupViewModeUIProps = {
  rewardGroups: PromotionCampaignValidationRewardGroup[]
}

export const PromotionRewardGroupsViewModeUI = (
  props: PromotionRewardGroupViewModeUIProps
) => {
  return (
    <>
      {props.rewardGroups.map((rewardGroup, index) => {
        return (
          <div key={`reward-group-${index}`}>
            <div
              className={getBlockClass(
                PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
                'display-header'
              )}
            >
              Reward Group {index + 1}
            </div>
            <div
              className={getBlockClass(
                PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
                'display-field'
              )}
            >
              Name
              <span>{rewardGroup.name}</span>
            </div>
            <div
              className={getBlockClass(
                PROMOTION_SIDEBAR_FORM_ROOT_CLASS,
                'display-field'
              )}
            >
              Number of Entries
              <span>{rewardGroup.promotionRewards[0].quantity}</span>
            </div>
            <PromotionRulesViewModeUI rules={rewardGroup.rules} />
          </div>
        )
      })}
    </>
  )
}
